import ExtendableError from 'es6-error'

export const SEVERITY = {
	ERROR: 'error',
	WARNING: 'warning',
} as const

export type Severity = (typeof SEVERITY)[keyof typeof SEVERITY]

// Base error handling
export abstract class BaseError extends ExtendableError {
	severity: Severity = SEVERITY.ERROR
	declare abstract readonly detail?: string
	inner?: Error

	constructor() {
		super()
	}
}

// Module broken due to a dependency being broken
export class DependencyCascadeError extends BaseError {
	override message = 'Dependency error cascade.'

	private dependency: string

	constructor(opts: { dependency?: string }) {
		super()
		this.dependency = opts.dependency ?? '[Not Specified]'
	}

	get detail() {
		return `The ${this.dependency} module, which this module depends on, has failed.`
	}
}

// Global Errors
export abstract class GlobalError extends BaseError { }

export class ReportNotFoundError extends GlobalError {
	override severity = SEVERITY.WARNING
	override message = 'Report not found.'
	detail = 'The report specified either does not exist, or is private. Make sure you pasted the correct URL, and your log is either public or unlisted.'
}

// TODO: Bit of repetition. Should these be combined, or...?
export class NotFoundError extends GlobalError {
	override severity = SEVERITY.WARNING
	override message = 'Not found.'

	private type: string

	constructor(opts: { type?: string }) {
		super()
		this.type = opts.type ?? '[Not Specified]'
	}

	get detail() {
		return `No ${this.type} was found with the specified ID. If this report was generated by live logging, it may have been cached - try pressing Refresh to reload it.`
	}
}

export class DidNotParticipateError extends GlobalError {
	override severity = SEVERITY.WARNING
	override message = 'Didn\'t participate.'

	private combatant: string
	private fight: number

	constructor(opts: { combatant?: string, fight?: number }) {
		super()
		this.combatant = opts.combatant ?? '[Not Found]'
		this.fight = opts.fight ?? 0
	}

	get detail() {
		return `Combatant ${this.combatant} did not take part in fight ${this.fight}.`
	}
}

export class TooManyRequestsError extends GlobalError {
	override message = 'Slow down.'
	detail = 'Too many requests. Please wait a little while before trying again.'
}

export class UnknownApiError extends GlobalError {
	override message = 'API Error.'
	detail = 'An error occured while requesting data from FFLogs. If this issue persists, let us know on Discord.'

	constructor(opts: { inner?: Error }) {
		super()
		this.inner = opts.inner
	}
}

export class ModulesNotFoundError extends GlobalError {
	override message = 'Modules not found.'
	detail = 'A new version has probably been deployed. If refreshing the page does not fix this error, please let us know on Discord!'
}

export class ReportProcessingError extends GlobalError {
	override message = 'Report is still processing.'
	detail = 'FF Logs takes a brief period to process reports before xivanalysis is able to analayse them. Try again shortly!'
}
